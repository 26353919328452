export type Part = typeof PARTS[number];
export const PARTS = [
  "solid_biofuel",
  "caterium_ingot",
  "copper_ingot",
  "copper_ficsmas_ornament",
  "ai_limiter",
  "rubber",
  "petroleum_coke",
  "iron_ficsmas_ornament",
  "battery",
  "wire",
  "circuit_board",
  "radio_control_unit",
  "black_powder",
  "automated_wiring",
  "compacted_coal",
  "steel_beam",
  "crystal_oscillator",
  "steel_pipe",
  "ficsmas_bow",
  "candy_cane",
  "alclad_aluminum_sheet",
  "screw",
  "packaged_fuel",
  "heat_sink",
  "smart_plating",
  "computer",
  "aluminum_ingot",
  "iron_ingot",
  "uranium_pellet",
  "motor",
  "stator",
  "versatile_framework",
  "empty_canister",
  "ficsmas_ornament_bundle",
  "uranium_cell",
  "snowball",
  "adaptive_control_unit",
  "industrial_beam",
  "cable",
  "quickwire",
  "ficsmas_tree_branch",
  "steel_ingot",
  "high_speed_connector",
  "reinforced_plate",
  "heavy_modular_frame",
  "polymer_resin",
  "packaged_heavy_oil_residue",
  "turbo_motor",
  "red_ficsmas_ornament",
  "packaged_liquid_biofuel",
  "copper_sheet",
  "biofuel",
  "concrete",
  "plastic",
  "supercomputer",
  "blue_ficsmas_ornament",
  "ficsmas_decoration",
  "packaged_turbofuel",
  "iron_plate",
  "modular_engine",
  "nobelisk",
  "silica",
  "aluminum_scrap",
  "alumina_solution",
  "ficsmas_gift",
  "electromagnetic_control_rod",
  "snowball_pile",
  "iron_rod",
  "rotor",
  "steel_plate",
  "quartz_crystal",
  "modular_frame",
  "nuclear_fuel_rod",
] as const;

export const partTypeReadable: {
  [key in Part]: string;
} = {
  steel_beam: "Steel Beam",
  steel_pipe: "Steel Pipe",
  steel_ingot: "Steel Ingot",
  iron_ingot: "Iron Ingot",
  heavy_modular_frame: "Heavy Modular Frame",
  industrial_beam: "Industrial Beam",
  iron_plate: "Iron Plate",
  modular_frame: "Modular Frame",
  reinforced_plate: "Reinforced Plate",
  screw: "Screw",
  caterium_ingot: "Caterium Ingot",
  circuit_board: "Circut Board",
  computer: "Computer",
  copper_ingot: "Copper Ingot",
  plastic: "Plastic",
  quickwire: "Quickwire",
  rubber: "Rubber",
  concrete: "Concrete",
  cable: "Cable",
  copper_sheet: "Copper Sheet",
  wire: "Wire",
  iron_rod: "Iron Rod",
  adaptive_control_unit: "Adaptive Control Unit",
  ai_limiter: "AI Limiter",
  alclad_aluminum_sheet: "Alclad Aluminum Sheet",
  aluminum_ingot: "Aluminum Ingot",
  aluminum_scrap: "Aluminum Scrap",
  high_speed_connector: "High Speed Connector",
  empty_canister: "Empty Canister",
  modular_engine: "Modular Enginer",
  nuclear_fuel_rod: "Nuclear Fuel Rod",
  crystal_oscillator: "Crystal Oscillator",
  battery: "Battery",
  packaged_fuel: "Packaged Fuel",
  rotor: "Rotor",
  electromagnetic_control_rod: "Electromagnetic Control Rod",
  polymer_resin: "Polymer Resin",
  steel_plate: "Steel Plate",
  uranium_cell: "Uranium Cell",
  packaged_liquid_biofuel: "Packaged Liquid Biofuel",
  ficsmas_tree_branch: "Ficmas Tree Branch",
  biofuel: "Boifuel",
  smart_plating: "Smart Plating",
  ficsmas_gift: "Ficmas Gift",
  solid_biofuel: "Solid Biofuel",
  supercomputer: "Supercomputer",
  ficsmas_ornament_bundle: "Ficmas Ornament Bundle",
  packaged_heavy_oil_residue: "",
  snowball_pile: "Snowball Pile",
  heat_sink: "Heatsink",
  motor: "Motor",
  snowball: "Snowball",
  candy_cane: "Candy Cane",
  copper_ficsmas_ornament: "Copper Ficmas Ornament",
  stator: "Stator",
  blue_ficsmas_ornament: "Blue Ficmas Ornament",
  automated_wiring: "Automated Wiring",
  turbo_motor: "Turbo Motor",
  radio_control_unit: "Radio Control Unit",
  nobelisk: "Nobelisk",
  ficsmas_decoration: "Ficmas Decoration",
  compacted_coal: "Compacted Coal",
  quartz_crystal: "Quartz Crystal",
  silica: "Silica",
  black_powder: "Black Poweder",
  versatile_framework: "Versatile Framework",
  ficsmas_bow: "Ficmas Bow",
  packaged_turbofuel: "Packaged Turbofuel",
  red_ficsmas_ornament: "Ficmas Ornament",
  uranium_pellet: "Uranium Pellet",
  petroleum_coke: "Petroleum Coke",
  iron_ficsmas_ornament: "Iron Ficmas Ornament",
  alumina_solution: "Alumina Solution",
};

export const isPart = (part: any): part is Part => {
  return PARTS.includes(part);
};
